import React from "react";
import { Link } from "react-router-dom";

export function CustomNavLink(props) {
  const { children, className = "" } = props;
  return (
    <Link
      {...props}
      className={`font-bebas text-black text-[23px] hover:!text-[#eb6e1e] ${className}`}
    >
      {children}
    </Link>
  );
}

export function CustomLink(props) {
  const { children, className = "" } = props;
  return (
    <Link
      {...props}
      className={`font-robot text-black text-[16px] hover:!text-[#eb6e1e] ${className}`}
    >
      {children}
    </Link>
  );
}
export function CustomLinkButton(props) {
  const { children, className = "" } = props;
  return (
    <button
      {...props}
      className={`font-robot text-black text-[16px] hover:!text-[#eb6e1e] ${className}`}
    >
      {children}
    </button>
  );
}
