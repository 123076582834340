import { createContext, useCallback, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("pamiuser", null);
  const [cart, setCart] = useLocalStorage("cart", []);
  const [coupon, setCoupon] = useLocalStorage("coupon", null);
  const [amount, setAmount] = useLocalStorage("amount", 0);

  const login = useCallback(async (data) => {
    navigate("/", { replace: true });
    setUser(data);
  });
  const loginWithoutNavigate = useCallback(
    async (data) => {
      setUser(data);
    },
    [setUser],
  );
  const updateUser = useCallback(
    async (data) => {
      setUser(data);
    },
    [setUser],
  );
  const signup = useCallback(
    async (data) => {
      navigate("/", { replace: true });
      setUser(data);
    },
    [navigate, setUser],
  );
  const signupWithoutNavigate = useCallback(
    async (data) => {
      setUser(data);
    },
    [setUser],
  );
  const logout = useCallback(() => {
    navigate("/", { replace: true });
    setUser(null);
    setCart([]);
    setCoupon(null);
  }, [navigate, setUser, setCart]);
  const logoutWithoutNav = useCallback(() => {
    setUser(null);
    navigate("/", { replace: true });
  }, [navigate, setUser]);
  const addToCart = useCallback(
    (product, quantityToAdd = 1) => {
      const cartItems = cart || [];
      const existingItem = cartItems?.find((item) => item?.id === product?.id);
      if (existingItem) {
        if (quantityToAdd > 1) {
          existingItem.quantity = quantityToAdd;
        } else {
          existingItem.quantity += 1;
        }
      } else {
        const newItem = {
          id: product?.id,
          quantity: quantityToAdd,
          productDetails: product,
        };
        cartItems.push(newItem);
      }

      setCart(cartItems);

      const totalAmount = cartItems.reduce((acc, product) => {
        return acc + product?.quantity * product?.productDetails?.price;
      }, 0);

      const _tax = totalAmount * 0.4;
      setAmount(totalAmount + _tax);
    },
    [setCart, setAmount],
  );
  const removeFromCart = useCallback(
    (product) => {
      const cartItems = cart || [];
      const existingItem = cartItems.find((item) => item?.id === product?.id);
      if (existingItem) {
        if (existingItem?.quantity === 1) {
          const index = cartItems.findIndex((e) => e?.id === product?.id);
          cartItems.splice(index, 1);
          setCart(cartItems);
        } else {
          existingItem.quantity -= 1;
        }
      }
      setCart(cartItems);
      const totalAmount = cartItems.reduce((acc, product) => {
        return acc + product?.quantity * product?.productDetails.price;
      }, 0);

      const _tax = totalAmount * 0.1;
      setAmount(totalAmount + _tax);
    },
    [setCart, setAmount],
  );
  const removeFromCartOverwrite = useCallback(
    (product, newQuantity) => {
      const cartItems = cart || [];
      const existingItem = cartItems.find((item) => item?.id === product?.id);

      if (existingItem && newQuantity > 0) {
        existingItem.quantity = newQuantity;
      } else if (existingItem) {
        const index = cartItems.findIndex((e) => e?.id === product?.id);
        cartItems.splice(index, 1);
      }

      setCart(cartItems);

      const totalAmount = cartItems.reduce((acc, product) => {
        return acc + product?.quantity * product?.productDetails.price;
      }, 0);

      const _tax = totalAmount * 0.1;
      setAmount(totalAmount + _tax);
    },
    [setCart, setAmount],
  );
  const deleteFromCart = useCallback(
    (product) => {
      const cartItems = cart || [];
      const index = cartItems.findIndex((item) => item?.id === product?.id);

      if (index !== -1) {
        cartItems.splice(index, 1);
        setCart(cartItems);

        const totalAmount = cartItems.reduce((acc, product) => {
          return acc + product?.quantity * product?.productDetails.price;
        }, 0);

        const _tax = totalAmount * 0.1;
        setAmount(totalAmount + _tax);
      }
    },
    [setCart, setAmount],
  );
  const addCoupon = useCallback(
    (data) => {
      console.log(data);
      setCoupon(data);
      setAmount(amount - data?.discount);
    },
    [setCoupon],
  );
  const removeCoupon = useCallback(() => {
    setAmount(amount + coupon?.discount);
    setCoupon(null);
  });
  const value = useMemo(
    () => ({
      user,
      updateUser,
      signup,
      signupWithoutNavigate,
      login,
      loginWithoutNavigate,
      logout,
      logoutWithoutNav,
      cart,
      setCart,
      addToCart,
      removeFromCart,
      removeFromCartOverwrite,
      deleteFromCart,
      amount,
      coupon,
      addCoupon,
      removeCoupon,
    }),
    [
      user,
      updateUser,
      signup,
      signupWithoutNavigate,
      login,
      loginWithoutNavigate,
      logout,
      logoutWithoutNav,
      cart,
      setCart,
      addToCart,
      removeFromCart,
      removeFromCartOverwrite,
      deleteFromCart,
      amount,
      coupon,
      addCoupon,
      removeCoupon,
    ],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
